<template>
    <div class="lang-switcher">
        <ul class="d-flex ps-0 ps-sm-auto">
            <li
                class="lang-item text-center"
                v-for="lang in langs"
                :class="{active:lang.active}"
                :key="`locale-${lang.key}`"
                @click="changeLang(lang.key)"
            >
                {{ lang.title }}
            </li>
        </ul>
    </div>
</template>

<script>

export default {
    data: () => ({
        current: '',
        langs: []
    }),
    computed: {
        _langs: {
            get() {
                return {
                    ru: { key: 'ru', title: this.$t('lang-switcher.ru'), active: false },
                    kk: { key: 'kk', title: this.$t('lang-switcher.kk'), active: false },
                    en: { key: 'en', title: this.$t('lang-switcher.en'), active: false }
                }
            }
        },
        currentLang() {
            return this.$route.params.lang;
        }
    },
    methods: {
        getCurrent() {
            let l
            if(this.currentLang!=undefined) {
                l = this.currentLang
            }
            else if(this.$route.path == "/") {
                l = 'ru'
            }
            else{
                l = this.$libs.cookies.getCookie('lang') || this.$i18n.locale
            }
            this.$libs.cookies.setCookie('lang', l)
            this.$i18n.locale = l
            this.current = this.$i18n.locale
        },
        getLangs() {
            this.langs = this._langs

            // set current active
            for(let i in this.langs) {
                this.langs[i].active = this.current == i
            }
        },
        changeLang(lang) {
            const currentPath = this.$route.path;
            let newPath = currentPath;

            if (currentPath.match(/^\/(ru|en|kk)\//)) {
                newPath = currentPath.replace(/^\/(ru|en|kk)\//, `/${lang}/`);
                if(newPath=='/ru/')
                    this.$router.push('/').then(() => {
                        this.$router.go()
                    });
                else 
                    this.$router.push(newPath).then(() => {
                        this.$router.go()
                    });
            } 
            else if(this.$route.path == "/") {
                newPath = currentPath + `${lang}/`
                this.$router.push(newPath).then(() => {
                    this.$router.go()
                });
            }
            else {
                this.$i18n.locale = lang
                this.current = this.$i18n.locale

                this.$libs.cookies.setCookie('lang', this.$i18n.locale)

                for(let i in this.langs) {
                    this.langs[i].active = this.current == this.langs[i].key
                }

                this.$router.go()
            }
        }
    },
    mounted() {
        this.getCurrent()
        this.getLangs()
    }
}

</script>

<style scoped>

ul {
    list-style-type: none;
    margin: auto;
}

.lang-switcher {
    color: #fff;
}

.lang-item {
    width: 50px;
    height: auto;
    padding: 0 .5rem;
    border-left: 1px solid;
    cursor: pointer;
    line-height: 14px;
}

.lang-item:first-child {
    border-left: none;
}

.lang-item.active {
    font-weight: bold;
}

</style>