const rates = Object.freeze({
    // free: 1,
    standard: 1,
    enterprise: 2
})

async function getUserRate(userId) {
    let tRates = 'rates',
        tRatesUser = 'rates_user'

    let sql = `SELECT r.id, r.name, r.price, r.desc, r.subscription, r.claim_cost, r.paid_delivery
        FROM ${tRates} r
        JOIN ${tRatesUser} ru ON ru.rate_id = r.id
        WHERE ru.user_id = ${userId} LIMIT 1`

    let q = await db.query(sql)

    return q[0]
}

module.exports = {
    rates,
    getUserRate
}