<template>
    <div class="d-flex flex-column flex-shrink-0 sticky-top" id="sidebar" :class="{show:sidebarShow}" :style="'width: ' + width + 'px;'">
        <a href="/" class="text-white text-decoration-none pd-left-2">
            <div class="logo">
                <img v-if="locale=='ru' || locale=='en'" src="../assets/img/logo-text-ru-white.png" class="img-fluid static-img" alt="">
                <img v-if="locale=='kk' "src="../assets/img/logo-text-kz-white.png" class="img-fluid static-img" alt="">
            </div>
        </a>
        <ul class="nav flex-column mb-auto scroll-enabled">
            <sidebar-child v-for="(n, index) in _navs" :key="index" :ref="child-component" @sidebarHide="sidebarHide"
            :item="n" :inboxCount="inboxCount" :outboxCount="outboxCount"></sidebar-child>
            <div class="rel disabled-on-mobile width-100">
                <li class="nav-item">
                    <button type="button" @click.prevent="logout()" class="nav-link pd-left-2 width-100">
                        <i class="bi bi-door-open-fill"></i> {{ $t('sidebar.logout') }}
                    </button>
                </li>
            </div>
        </ul>
        <div>
            <small class="build-version pd-left-2">{{ $t('sidebar.build-version') + getAppVersion() }}</small>
        </div>
    </div>
    <nav class="navbar navbar-expand-lg fixed-bottom" id="mobileNavbar">
        <div id="mobileNavbarNav">
            <ul class="navbar-nav flex-row justify-content-between">
                <li class="nav-item">
                    <button type="button" class="btn btn-link" @click="sidebarShow = !sidebarShow">
                        <i class="bi" :class="sidebarShow ? 'bi-x' : 'bi-list'"></i>
                        <span class="nav-title">{{ $t('mobile-tabs.menu') }}</span>
                    </button>
                </li>
                <li class="nav-item">
                    <router-link type="button" class="btn btn-link" to="/dashboard" @click="sidebarCollapse()">
                        <i class="bi bi-display"></i>
                        <span class="nav-title">{{ $t('mobile-tabs.main') }}</span>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link type="button" class="btn btn-link" to="/claims/new" @click="sidebarCollapse()">
                        <i class="bi bi-file-earmark-plus-fill"></i>
                        <span class="nav-title">{{ $t('mobile-tabs.claim') }}</span>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link type="button" class="btn btn-link" to="/claims/inbox" @click="sidebarCollapse()">
                        <i class="bi bi-inboxes-fill"></i>
                        <span class="nav-title">{{ $t('mobile-tabs.inbox') }}</span>
                    </router-link>
                </li>
            </ul>
        </div>
    </nav>
    <PolicyAgreementModal ref="policyAgreementModal" @agreed="agreed" />
    <AgreementFetchFailModal ref="agreementFetchFailModal" @logout="logout" />
    <SetPasswordModal ref="setPasswordModal" @passwordSet="passwordSet" />
    <ClaimPreviewModal ref="claimPreviewModal" @closed="handleClaimPreviewClose" />
    <DebtPreviewModal ref="debtPreviewModal" @closed="handleDebtPreviewClose" />
    <NotifySenderModal ref="notifySenderModal" :notice="debt_notice" @closed="handleNotifySenderClose" />
</template>

<script>
    import SidebarChild from './SidebarChild.vue';
    import Menu from './menu.json'
    import AgreementFetchFailModal from './settings/AgreementFetchFailModal.vue'
    import PolicyAgreementModal from './settings/PolicyAgreementModal.vue'
    import { rates } from '../../libs/rate'
    import ChangePasswordModal from './settings/ChangePasswordModal.vue'
    import SetPasswordModal from './settings/SetPasswordModal.vue'
    import ClaimPreviewModal from './claims/ClaimPreviewModal.vue'
    import DebtPreviewModal from './debt/PreviewModal.vue'
    import NotifySenderModal from './debt/NotifySenderModal.vue'

    export default {
        data: () => ({
            width: 280,
            navs: Menu,
            inboxCount: 0,
            outboxCount: 0,
            sidebarShow: false,
            agreedToPolicy: false,
            currentPlan: {},
            hasPassword: false,
            debt_notice: {}
        }),
        components: {
            PolicyAgreementModal,
            AgreementFetchFailModal,
            ChangePasswordModal,
            SetPasswordModal,
            ClaimPreviewModal,
            SidebarChild,
            DebtPreviewModal,
            NotifySenderModal
        },
        computed: {
            _navs: {
                get() {
                    let locales = {
                        'dashboard': this.$t('sidebar.dashboard'),
                        'claims': this.$t('sidebar.claims'),
                        'new-claim': this.$t('sidebar.new-claim'),
                        'import-claims': this.$t('sidebar.import-claims'),
                        'inbox-claim': this.$t('sidebar.inbox-claim'),
                        'utility-debts-inbox-claim': this.$t('sidebar.utility-debts-inbox-claim'),
                        'cash-loans-inbox-claim': this.$t('sidebar.cash-loans-inbox-claim'),
                        'products-inbox-claim': this.$t('sidebar.products-inbox-claim'),
                        'outbox-claim': this.$t('sidebar.outbox-claim'),
                        'recipients': this.$t('sidebar.recipients'),
                        'add-recipients': this.$t('sidebar.add-recipients'),
                        'debt-notifications': this.$t('sidebar.debt-notifications'),
                        'new-debt-notification': this.$t('sidebar.new-debt-notification'),
                        'debt-notification-import': this.$t('sidebar.debt-notification-import'),
                        'outbox-debt-notification': this.$t('sidebar.outbox-debt-notification'),
                        'inbox-debt-notification': this.$t('sidebar.inbox-debt-notification'),
                        'settings': this.$t('sidebar.settings'),
                        'payment-schedules': this.$t('sidebar.payment-schedules'),
                        'profile-settings': this.$t('sidebar.profile-settings'),
                        'payment-settings': this.$t('sidebar.payment-settings'),
                        'permissions': this.$t('sidebar.permissions'),
                        'users': this.$t('sidebar.users')
                    }

                    // translate
                    for(let i in this.navs) {
                        this.navs[i].text = this.navs[i].slug in locales ? locales[this.navs[i].slug] : this.navs[i].slug

                        if(this.navs[i].sub!=[]) {
                            this.navs[i].sub = this.addSubText(locales, this.navs[i].sub)
                        }
                    }

                    return this.navs
                }
            },
            locale() {
                const locale = this.$i18n.locale;
                return locale
            }
        },
        methods: {
            addSubText(locales, navs) {
                for(let j in navs ?? []) {
                    navs[j].text = navs[j].slug in locales ? locales[navs[j].slug] : navs[j].slug
                    if(navs[j].sub!=[]) {
                        navs[j].sub = this.addSubText(locales, navs[j].sub)
                    }
                }
                return navs
            },
            getAppVersion() {
                return process.env.VUE_APP_VERSION
            },
            getUsername() {
                if(this.$store.state.user) {
                    let username = ''
                    
                    if(this.$store.state.user.settings.firstName) username += firstName
                    if(this.$store.state.user.settings.lastName) username += username ? ' ' + lastName : lastName
                    if(this.$store.state.user.settings.patronymic) username += username ? ' ' + patronymic : patronymic

                    return username ? username : 'Пользователь'
                }

                return 'Пользователь'
            },
            getInboxCount() {
                let url = process.env.VUE_APP_API_URL + '/claims/inbox-count'

                axios.get(url, {
                    withCredentials: true
                }).then((response) => {
                    let r = response.data

                    if(r.result = 'SUCCESS') {
                        if(r.data >= 0) this.inboxCount = r.data

                        this.$emit('updatedInboxCount', this.inboxCount)

                        // if(this.inboxCount > 0) {
                        //     this.$store.commit('showModal', {
                        //         title: 'Новые уведомление',
                        //         body: `<p>Количество новых уведомлений: ${this.inboxCount}</p>`
                        //     })
                        // }
                    } else {
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: 'Не удалось проверить количество входящих',
                            type: 'error'
                        })
                    }
                })
            },
            getOutboxCount() {
                let url = process.env.VUE_APP_API_URL + '/claims/outbox-count'

                axios.get(url, {
                    withCredentials: true
                })
                .then((response) => {
                    let r = response.data

                    if(r.result = 'SUCCESS') {
                        if(r.data >= 0) this.outboxCount = r.data

                        this.$emit('updatedOutboxCount', this.outboxCount)
                    } else {
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: 'Не удалось проверить количество исходящих' + r.result,
                            type: 'error'
                        })
                    }
                })
            },
            getInboxCountForNav() {
                if(this.inboxCount > 99) return '99+'
                return this.inboxCount
            },
            getOutboxCountForNav() {
                if(this.outboxCount > 99) return '99+'
                return this.outboxCount
            },
            logout() {
                this.$store.commit('clearClaim')
                
                const url = process.env.VUE_APP_API_URL + '/logout'

                axios.get(url, {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }).then(response => response).then(async res => {
                    let r = await res.data

                    if (r.result === 'SUCCESS') {
                        this.$store.commit('logout')
                        this.$router.push('/login')
                    } else {
                        this.email = '', this.password = ''
                        console.error('Auth failed')
                    }
                })
            },
            checkMyRecipient() {
                // if is user logged right now, else return
                if(!this.$store.state.loggedNow) return
                this.$store.state.loggedNow = false

                let recipient = {
                    user_id: this.$store.state.user.id,
                    firstName: this.$store.state.user.settings.firstName ?? '',
                    lastName: this.$store.state.user.settings.lastName ?? '',
                    patronymic: this.$store.state.user.settings.patronymic ?? '',
                    id_no: this.$store.state.user.settings.iinBin ?? '',
                    companyIdNum: this.$store.state.user.settings.bin ?? '',
                    companyName: this.$store.state.user.settings.organization ?? ''
                }

                // check if id_no empty
                if(!recipient.id_no) return

                let url = process.env.VUE_APP_API_URL + '/recipients/set-me'

                axios.post(url, recipient, {
                    withCredentials: true
                }).then((response) => {
                    let r = response.data
                    
                    if(r.result === 'SUCCESS') {
                        let has = r.data // true (has recipient with this id_no) | false (not found)

                        this.getInboxCount()
                        if(!has) this.createMyRecipient()
                    } else {
                        
                    }
                })
            },
            createMyRecipient() {
                // create recipient with this id_no
                let recipient = {
                    user_id: this.$store.state.user.id,
                    title: this.$store.state.user.username,
                    firstName: this.$store.state.user.settings.firstName,
                    lastName: this.$store.state.user.settings.lastName,
                    patronymic: this.$store.state.user.settings.patronymic ?? '',
                    id_no: this.$store.state.user.settings.iinBin ?? '',
                    companyIdNum: this.$store.state.user.settings.bin ?? '',
                    companyName: this.$store.state.user.settings.organization ?? '',
                    email: this.$store.state.user.settings.email ?? '',
                    phone: this.$store.state.user.settings.phone ?? '',
                    address: this.$store.state.user.settings.address ?? '',
                    creator: this.$store.state.user.id
                }

                let url = process.env.VUE_APP_API_URL + '/recipients/add'

                axios.post(url, recipient, {
                    withCredentials: true
                }).then((response) => {
                    let r = response.data
                    
                    if(r.result === 'SUCCESS') {
                        
                    } else {
                        
                    }
                })
            },
            async checkPolicyAgreement() {
                let url = process.env.VUE_APP_API_URL + '/users/agreement'

                await axios.get(url, {
                    withCredentials: true
                }).then(async (response) => {
                    let r = response.data

                    if(r.result === 'SUCCESS') { // if there is agreement record in db
                        if(r.data === 1) { // if user already agreed
                            this.agreedToPolicy = true

                            await this.checkPassword()

                            if(!this.hasPassword) {
                                this.$store.commit('modalShow', { id: 'setPasswordModal' })
                            }
                        } else { // if user has not agreed
                            this.$store.commit('modalShow', { id: 'policyAgreementModal' })
                        }
                    } else { // if no agreement record, create one
                        this.agreedToPolicy = false

                        await this.createAgreement()
                    }
                })
            },
            sidebarCollapse(disabled = false) {
                if(!disabled) {
                    this.sidebarShow = false
                }
            },
            sidebarHide() {
                //hide sidebar if mobile
                this.sidebarShow = false
            },
            async createAgreement() {
                let url = process.env.VUE_APP_API_URL + '/users/create-agreement'

                await axios.get(url, {
                    withCredentials: true
                }).then((response) => {
                    let r = response.data

                    if(r.result === 'SUCCESS') { // if new agreement created
                        this.$store.commit('modalShow', { id: 'policyAgreementModal' })
                    } else {
                        this.$store.commit('modalShow', { id: 'agreementFetchFailModal'}) // show modal saying 'failed to create policy agreement, please log out'
                    }
                })
            },
            createAccountBalance(user_id) {
                let url = process.env.VUE_APP_API_URL + '/accounts/my'
                axios.post(url, {user_id}, {
                    withCredentials: true,
                })
                .then((response) => {
                    let r = response.data
                    if (r.result === 'SUCCESS') {

                    }
                })
            },
            setDefaultRate() {
                let url = process.env.VUE_APP_API_URL + '/rates/set-current'
                axios.post(url, {rateId: 'default'}, {
                    withCredentials: true,
                })
                .then((response) => {
                    let r = response.data
                    if (r.result === 'SUCCESS') {

                    }
                })
            },
            async getCurrentPlan() {
                let url = process.env.VUE_APP_API_URL + '/rates/get-current-plan'

                await axios.get(url, {
                    withCredentials: true
                }).then((response) => {
                    let r = response.data

                    if(r.result === 'SUCCESS') {
                        this.currentPlan = r.data
                        if(this.currentPlan.id !== rates.enterprise) { // if not enterprise
                            this.navs = this.navs.filter(item => item.link !== '/claims/import')
                        }
                    } else {
                        this.navs = this.navs.filter(item => item.link !== '/claims/import')
                    }
                })

                this.$emit('updatedCurrentPlan', this.currentPlan)
            },
            async debtNoticeMenu() {
                if(!this.$store.state.user.settings) this.navs = this.navs.filter(item => item.link !== '/debt-notice')

                let isOsi = await this.$api.checkOsiStatus()

                if(isOsi) { // if osi
                    for(let k in this.navs) {
                        if(this.navs[k].sub) {
                            if(Array.isArray(this.navs[k].sub)) {
                                this.navs[k].sub = this.navs[k].sub.filter(item => item.link !== '/debt-notice/inbox')
                            }
                        }
                    }
                } else {
                    let r = await this.$api.checkNotices()


                    if(r.result === 'SUCCESS') {
                        if(!r.data) this.navs = this.navs.filter(item => item.link !== '/debt-notice')
                        if(r.data) {
                            for(let k in this.navs) {
                                if(this.navs[k].sub) {
                                    if(Array.isArray(this.navs[k].sub)) {
                                        this.navs[k].sub = this.navs[k].sub.filter(item => item.link !== '/debt-notice/new')
                                        this.navs[k].sub = this.navs[k].sub.filter(item => item.link !== '/debt-notice/outbox')
                                        this.navs[k].sub = this.navs[k].sub.filter(item => item.link !== '/debt-notice/import')
                                    }
                                }
                            }
                        }
                    } else {
                        this.navs = this.navs.filter(item => item.link !== '/debt-notice')
                    }
                }
            },
            agreed() {
                this.agreedToPolicy = true

                this.checkPolicyAgreement()
            },
            passwordSet() {
                this.$refs.setPasswordModal.close()
            },
            async checkPassword() {
                let url = process.env.VUE_APP_API_URL + '/users/has-password'

                await axios.get(url, {
                    withCredentials: true
                }).then((response) => {
                    let r = response.data

                    if(r.result === 'SUCCESS') {
                        this.hasPassword = r.data
                    } else {
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: this.$t('sidebar.notify.password-error'),
                            type: 'error'
                        })
                    }
                })
            },
            async getUnreadClaimPreview() {
                let r = await this.$api.getUnreadClaim()

                if(r.data ?? false) {
                    this.$refs.claimPreviewModal.claim = r.data
                    this.$refs.claimPreviewModal.refresh()
                    this.$store.commit('modalShow', { id: 'claimPreviewModal' })
                }
            },
            async getUnreadDebtNotice() {
                let r = await this.$api.getUnreadDebtNotice()
                
                if(r ?? false) {
                    if(r.result && r.result === 'ERROR') {
                        return
                    }

                    this.$refs.debtPreviewModal.notice = r
                    await this.$refs.debtPreviewModal.getSenderPhone()
                    await this.$refs.debtPreviewModal.getSenderTitle()
                    await this.$refs.debtPreviewModal.read(r.id)
                    this.$store.commit('modalShow', { id: 'debtNoticePreviewModal' })
                }
            },
            async checkNoticeStatuses() {
                let r = await this.$api.checkNoticeStatuses()

                if(r.result && r.result === "ERROR") {
                    return
                } else if (r.result === "SUCCESS") {
                    if(r.data) {
                        this.$store.commit('modalShow', { id: 'notifyNoticeSenderModal' })
                        this.updateNotice(r.data.id)
                        this.debt_notice = r.data
                    }
                }
            },
            async updateNotice(noticeId) {
                let r = await this.$api.setNoticeSenderNotified(noticeId)

                if(r.result === 'ERROR') {
                    this.$notify({
                        title: this.$t('notify.types.error'),
                        text: r.message,
                        type: 'error'
                    })
                }
            },
            handleClaimPreviewClose() {
                this.getUnreadClaimPreview()
            },
            handleDebtPreviewClose() {
                this.getUnreadDebtNotice()
            },
            handleNotifySenderClose() {
                this.checkNoticeStatuses()
            }
        },
        mounted() {
            this.checkPolicyAgreement()
            this.getInboxCount()
            this.getOutboxCount()
            this.checkMyRecipient()
            this.getAppVersion()
            this.createAccountBalance()
            this.setDefaultRate()
            this.getCurrentPlan()

            this.debtNoticeMenu()
            this.getUnreadDebtNotice()
            this.checkNoticeStatuses()

            if(this.$libs.cookies.getCookie('claimPreview')) {
                this.$store.commit('modalShow', { id: 'claimPreviewModal' })
            } else {
                this.getUnreadClaimPreview()
            }
        }
    }
</script>

<style scoped>
    @media (max-width:767px) {
        .scroll-enabled {
            max-height: 400px; /* Set a maximum height for the list */
            overflow-y: auto;
            display: block !important;
            max-height: 80%;
        }

        .pd-left-2 { 
            padding-left: 2em !important; 
        }

        .disabled-on-pc {
            display: block;
        }

        .disabled-on-mobile {
            display: none;
        }
    }

    /* PC Disabled */
    /* @media (min-width: 768px) {
        #sidebar {
            padding-left: 1em;
        }
    } */

    @media (min-width: 768px) {
        #sidebar {
            padding-left: 1em;
        }

        .scroll-enabled {
            max-height: 400px; /* Set a maximum height for the list */
            overflow-y: auto;
            display: block !important;
            max-height: 80%;
        }

        .scroll-enabled::-webkit-scrollbar {
            width: 5px;
        }
    
        .scroll-enabled::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
        }

        .pd-left-2{ 
            padding-left: 1.1em;
        }

        .disabled-on-pc {
            display: none;
        }

        .disabled-on-mobile {
            display: block;
        }

        .pd-left-2 { 
            padding-left: 2em !important; 
        }
    }

    .width-100 {
        width: 100%;
    }

    #sidebar {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        width: 280px;
        height: 100vh;
        color: #ffffff;
        background: rgb(20,13,62);
        background: -moz-linear-gradient(69deg, rgba(20,13,62,1) 0%, rgba(58,48,119,1) 100%);
        background: -webkit-linear-gradient(69deg, rgba(20,13,62,1) 0%, rgba(58,48,119,1) 100%);
        background: linear-gradient(69deg, rgba(20,13,62,1) 0%, rgba(58,48,119,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#140d3e",endColorstr="#3a3077",GradientType=1);
        /* box-shadow: .125rem 0 .75rem rgb(0 0 0 / 50%); */
        z-index: 3;
    }

    .static-img {
        height:49px
    }

    .logo {
        max-width: 140px;
        margin: 1rem .5rem 2.5rem;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .nav {
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    
    .nav-link.disabled {
        color: #8f82d8;
    }

    .nav-link,
    .btn-link {
        display: flex;
        align-items: center;
        padding: 0 .5rem;
        color: #c7c3e5;
        background: none;
        text-decoration: none;
        font-size: .85rem;
        font-weight: 400;
        text-transform: none;
        position: relative
    }

    .nav-link .bi,
    .btn-link .bi {
        color: #d98292;
        font-size: 1.5rem;
    }

    .nav-link:hover,
    .btn-link:hover {
        color: #ffffff;
        background: none;
    }

    .nav-link:focus,
    .btn-link:focus,
    #app .btn-link:active {
        color: #8f82d8;
        background: none;
    }

    #sidebar .nav-link .bi,
    #sidebar .btn-link .bi {
        margin-right: .75rem;
    }

    .nav-link.router-link-active {
        color: #ffffff;
    }

    .nav.sub .nav-link {
        font-weight: 400;
    }

    .badge {
        font-size: .65em;
    }

    .build-version {
        margin: .5rem;
        color: #8f82d8;
        font-size: .6rem;
    }

    #sidebar::-webkit-scrollbar {
        width: 5px;
    }
    
    #sidebar::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    }
    
    /* #sidebar::-webkit-scrollbar-thumb {
        background-color: #d98292;
        outline: 1px solid #d98292;
    } */

    #mobileNavbar {
        display: none;
        padding: .5em 1em;
        background: rgb(20,13,62);
        background: -moz-linear-gradient(69deg, rgba(20,13,62,1) 0%, rgba(58,48,119,1) 100%);
        background: -webkit-linear-gradient(69deg, rgba(20,13,62,1) 0%, rgba(58,48,119,1) 100%);
        background: linear-gradient(69deg, rgba(20,13,62,1) 0%, rgba(58,48,119,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#140d3e",endColorstr="#3a3077",GradientType=1);
        z-index: 4;
    }

    #mobileNavbar .nav-title {
        display: block;
    }

    #mobileNavbar .nav-link,
    #mobileNavbar .btn-link {
        display: block;
        color: #ffffff;
        text-align: center;
    }

    @media (max-width: 991px) {
        #sidebar {
            left: -100%;
            width: 100%;
        }

        #sidebar.show {
            left: 0;
        }

        #mobileNavbar {
            display: block;
        }
    }

</style>