<template>
    <Header />
    <div>
        <section class="cards-section">
            <div class="container d-flex align-items-center">
                <div class="row mb-5 justify-content-center" v-if="cards">
                    <div class="cards-col col-md-3 col-sm-4 mb-3" v-for="(c, index) in cards">
                        <div class="card cards-1 shadow rounded-4 h-100" :class="{active:cardActives[index]}" @click="activateCard(index)">
                            <div class="card-body">
                                <div class="m-3 d-flex justify-content-center">
                                    <img v-if="index==0" src="../assets/img/card-1.png" alt="" class="card-img card-img-1">
                                    <img v-if="index==1" src="../assets/img/card-2.png" alt="" class="card-img card-img-2">
                                    <img v-if="index==2" src="../assets/img/card-3.png" alt="" class="card-img card-img-3">
                                    <img v-if="index==3" src="../assets/img/card-4.png" alt="" class="card-img card-img-4">
                                </div>
                                <h5>{{ index+1 }}</h5>
                                <p class="card-title text-uppercase fw-bold">{{ cardTitles[index] }}</p>
                                <p class="card-text">{{ cardTexts[index] }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="slider-section">
            <div id="carousel" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-indicators">
                    <button type="button" data-bs-target="#carousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carousel" data-bs-slide-to="3" aria-label="Slide 4"></button>
                </div>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <div class="banner banner-bg-1">
                            <div class="row align-items-center">
                                <div class="col-md-6 order-2 order-md-1">
                                    <img src="../assets/img/banner-pic-1.png" class="img-fluid" alt="">
                                </div>
                                <div class="col-md-6 order-1 order-md-2">
                                    <h3 class="mb-0" v-html="$t('home.banner.item-1.title-1')"></h3>
                                    <p class="subtitle-1" v-html="$t('home.banner.item-1.subtitle-1')"></p>
                                    <h4 v-html="$t('home.banner.item-1.title-2')"></h4>
                                    <p v-html="$t('home.banner.item-1.subtitle-2')"></p>
                                    <router-link v-if="this.$store.state.user" to="/dashboard" class="btn btn-lg btn-primary" style="width: 150px" v-html="$t('home.banner.item-1.btn')"></router-link>
                                    <router-link v-if="!this.$store.state.user" to="/login" class="btn btn-lg btn-primary" style="width: 150px" v-html="$t('home.banner.item-1.btn')"></router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="banner banner-bg-2">
                            <div class="row align-items-center">
                                <div class="col-md-6">
                                    <p class="subtitle-1" v-html="$t('home.banner.item-2.subtitle-1')"></p>
                                    <h4 v-html="$t('home.banner.item-2.title-2')"></h4>
                                    <p v-html="$t('home.banner.item-2.subtitle-2')"></p>
                                    <router-link v-if="this.$store.state.user" to="/dashboard" class="btn btn-lg btn-primary" style="width: 150px" v-html="$t('home.banner.item-1.btn')"></router-link>
                                    <router-link v-if="!this.$store.state.user" to="/login" class="btn btn-lg btn-primary" style="width: 150px" v-html="$t('home.banner.item-2.btn')"></router-link>
                                </div>
                                <div class="col-md-6">
                                    <img src="../assets/img/banner-pic-2.png" class="img-fluid" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="banner banner-bg-3">
                            <div class="row align-items-center">
                                <div class="col-md-6 text-center">
                                    <h3 v-html="$t('home.banner.item-3.title-1')"></h3>
                                    <p class="subtitle-1" v-html="$t('home.banner.item-3.subtitle-1')"></p>
                                    <h4 v-html="$t('home.banner.item-3.title-2')"></h4>
                                    <p v-html="$t('home.banner.item-3.subtitle-2')"></p>
                                    <router-link v-if="this.$store.state.user" to="/dashboard" class="btn btn-lg btn-primary" style="width: 150px" v-html="$t('home.banner.item-1.btn')"></router-link>
                                    <router-link v-if="!this.$store.state.user" to="/login" class="btn btn-lg btn-primary" style="width: 150px" v-html="$t('home.banner.item-3.btn')"></router-link>
                                </div>
                                <div class="col-md-6">
                                    <img src="../assets/img/banner-pic-3.png" class="img-fluid" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="banner banner-bg-4">
                            <div class="row align-items-center">
                                <div class="col-md-6">
                                    <p class="subtitle-1" v-html="$t('home.banner.item-4.subtitle-1')"></p>
                                    <h4 v-html="$t('home.banner.item-4.title-2')"></h4>
                                    <p v-html="$t('home.banner.item-4.subtitle-2')"></p>
                                    <router-link v-if="this.$store.state.user" to="/dashboard" class="btn btn-lg btn-primary" style="width: 150px" v-html="$t('home.banner.item-1.btn')"></router-link>
                                    <router-link v-if="!this.$store.state.user" to="/login" class="btn btn-lg btn-primary" style="width: 150px" v-html="$t('home.banner.item-4.btn')"></router-link>
                                </div>
                                <div class="col-md-6">
                                    <img src="../assets/img/banner-pic-4.png" class="img-fluid" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section class="video-section" ref="videoSection">
            <div class="container video-centered">
                <div class="video-container" ref="videoContainer">
                    <iframe src="https://www.youtube.com/embed/WXO0ciIKf60?rel=0&controls=0&showinfo=0&modestbranding=1" 
                frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen></iframe>
                </div>
            </div>
        </section>
        <section v-if="!hideFaq" class="py-60 faq-section">
            <div class="container text-control-1">
                <h2 class = "margin-4 width-92">{{ $t('faq.title') }}</h2>
                <div class="faqs-section">
                    <div
                    v-for="(faq, index) in faqs"
                    :key="index"
                    class="faq accordion"
                    :class="{ expanded: isExpanded(index) }"
                    >
                        <div class="question-wrapper" @click="toggleAccordion(index)">
                            <div class="d-flex align-items-center">
                                <span class="q-mark d-block">Q.</span>
                                <p class="question">{{ faq.question }}</p>
                            </div>
                            <i class="bi bi-chevron-right bi-sm chevron-right material-icons drop" :class="{ 'icon-expend': isExpanded(index) }">
                            </i>
                        </div>
                        <transition
                            name="slide-fade"
                            @before-enter="beforeEnter"
                            @enter="enter"
                            @leave="leave"
                        >
                            <div v-show="isExpanded(index)" class="answer-wrapper">
                                <div class="answer" v-html="faq.answer"></div>
                            </div>
                        </transition>
                    </div>
                    <div class="d-flex justify-content-center"><router-link class="nav-item rounded text-uppercase mt-3" :to="{ path: `/${this.$i18n.locale}/faq` }">{{ $t('faq.all') }}</router-link></div>
                </div>
            </div>
        </section>
        <section class="instruction-section d-flex align-items-center">
            <div class="container">
                <h2 class="section-heading">{{ $t('home.how_to_open_dp') }}</h2>
                <div class="row text-center justify-content-around">
                    <div class="step col-md-2 col-sm-6">
                        <h2 class="steps-color m-0">1</h2>
                        <h5 class="steps-color">{{ stepsTitles[0] }}</h5>
                        <p>{{ stepsDesc[0] }}</p>
                        <img class="steps-img " src="../assets/img/inst-1.png" alt="">
                    </div>
                    <div class="step col-md-2 col-sm-6">
                        <h2 class="steps-color m-0">2</h2>
                        <h5 class="steps-color">{{ stepsTitles[1] }}</h5>
                        <p>{{ stepsDesc[1] }}</p>
                        <img class="steps-img " src="../assets/img/inst-2.png" alt="">
                    </div>
                    <div class="step col-md-2 col-sm-6">
                        <h2 class="steps-color m-0">3</h2>
                        <h5 class="steps-color">{{ stepsTitles[2] }}</h5>
                        <p>{{ stepsDesc[2] }}</p>
                        <img class="steps-img " src="../assets/img/inst-3.png" alt="">
                    </div>
                    <div class="step col-md-2 col-sm-6">
                        <h2 class="steps-color m-0">4</h2>
                        <h5 class="steps-color">{{ stepsTitles[3] }}</h5>
                        <p>{{ stepsDesc[3] }}</p>
                        <img class="steps-img " src="../assets/img/inst-4.png" alt="">
                    </div>
                </div>
            </div>
        </section>
        <section class="claims-check-section">
            <div class="container">
                <ClaimsCheck />
            </div>
        </section>
        <section v-if="!hideArticles" class="infolinks-section">
            <div class="container d-flex flex-wrap justify-content-center">
                <div class="row">
                    <h2 class="section-heading info-title">{{ $t('home.info_title') }}</h2>
                </div>
                <div class="row flex-wrap align-self-start g-4">
                    <div class="col-12 col-md-6" v-for="(link, index) in infoLinks">
                        <div class="card rounded-0 cards-banner" :style="{ backgroundImage: 'url('+link.image+')' }">
                            <div class="card-body pb-0 d-flex flex-column justify-content-around">
                                <div class="row">
                                    <div class="col-10 h-100 align-content-center">
                                        <div class="links-heading">
                                            <h5 class="links-text">{{ link.title }}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-10 h-100 align-content-center">
                                        <router-link :to="{ path: `/${this.locale}/articles/${link.slug}` }" class="btn banner-btn ms-1">
                                            <h5 class="mb-0">{{ $t('read') }}</h5>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <Footer />
</template>

<script>

import Header from './home/Header.vue'
import Footer from './home/Footer.vue'
import Banner from './home/Banner.vue'
import ClaimsCheck from './home/ClaimsCheck.vue'

export default {
    data: () => ({
        cards: [],
        cardTitles: [],
        cardTexts: [],
        cardActives: [],
        dp_types: [],
        openDpSteps: [],
        stepsTitles: [],
        stepsDesc: [],
        infoLinks: [],
        expandedIndex: null,
        faqs: [],
        hideArticles: false,
        hideFaq: false
    }),
    computed: {
        _cards: {
            get() {
                return [
                    {
                        title: this.$t('home.cards.register.title'),
                        text: this.$t('home.cards.register.text'),
                        icon: this.$t('home.cards.register.icon'),
                        active: false
                    },
                    {
                        title: this.$t('home.cards.create.title'),
                        text: this.$t('home.cards.create.text'),
                        icon: this.$t('home.cards.create.icon'),
                        active: false
                    },
                    {
                        title: this.$t('home.cards.choice.title'),
                        text: this.$t('home.cards.choice.text'),
                        icon: this.$t('home.cards.choice.icon'),
                        active: false
                    },
                    {
                        title: this.$t('home.cards.send.title'),
                        text: this.$t('home.cards.send.text'),
                        icon: this.$t('home.cards.send.icon'),
                        active: false
                    }
                ]
            }
        },
        _openDpSteps: {
            get() {
                return [
                    {
                        title: this.$t('home.how_to_open_dp_steps.login.title'),
                        desc: this.$t('home.how_to_open_dp_steps.login.desc')
                    },
                    {
                        title: this.$t('home.how_to_open_dp_steps.create.title'),
                        desc: this.$t('home.how_to_open_dp_steps.create.desc')
                    },
                    {
                        title: this.$t('home.how_to_open_dp_steps.choice.title'),
                        desc: this.$t('home.how_to_open_dp_steps.choice.desc')
                    },
                    {
                        title: this.$t('home.how_to_open_dp_steps.send.title'),
                        desc: this.$t('home.how_to_open_dp_steps.send.desc')
                    }
                ]
            }
        },
        locale() {
            const locale = this.$i18n.locale;
            return locale
        }
    },
    components: {
        Header,
        Footer,
        Banner,
        ClaimsCheck
    },
    methods: {
        async getFaq() {
            if(this.loading) return
            this.loading = true

            let url = process.env.VUE_APP_API_URL + '/faq/get-homepage-faq'
            await axios.get(url, { 
                params:{
                    language: this.$i18n.locale
                }
            }).then((response) => {
                let r = response.data

                if(r.result === 'SUCCESS') {
                    this.faqs = r.data.questions
                    if(r.data.questions.length == 0)
                        this.hideFaq = true
                } else {
                    this.faqs= []
                    this.hideFaq = true
                }
            })

            this.loading = false
        },
        getCards() {
            let cards = this._cards

            // activate first card
            cards[0].active = true

            this.cards = cards

            for(let card of this.cards) {
                this.cardTitles.push(card.title)
                this.cardTexts.push(card.text)
                this.cardActives.push(false)
            }

            this.cardActives[0] = true
        },
        getDpTypes() {
            this.dp_types = this._dp_types
        },
        getOpenDpSteps() {
            this.openDpSteps = this._openDpSteps
            for(let step of this.openDpSteps) {
                this.stepsTitles.push(step.title)
                this.stepsDesc.push(step.desc)
            }
        },
        async getInfoLinks() {
            let url = process.env.VUE_APP_API_URL + '/articles/home-page'

            await axios.get(url, { 
                params:{
                    language: this.locale
                }
            })
            .then((response) => {
                let r = response.data

                if(r.result = 'SUCCESS') {
                    if(r.data.length == 0)
                        this.hideArticles = true
                    this.infoLinks = r.data
                }
                else {
                    this.infoLinks= []
                    this.hideArticles = true
                }
            })
        },
        activateCard(index) {
            for(let key in this.cardActives) {
                this.cardActives[key] = false
            }

            this.cardActives[index] = true
        },
        resizeVideoContainer() {
            try{
                const videoContainer = this.$refs.videoContainer;
                const width = videoContainer.clientWidth;
                const height = width * 9 / 16;
                videoContainer.style.height = `${height}px`;
            }
            catch(e) {
                return
            }
        },
        resizeSectionVideo() {
            const videoSection = this.$refs.videoSection;
            const width = videoSection.clientWidth;
            const height = width * 9 / 16;
            videoSection.style.height = `${height}px`;
        },
        toggleAccordion(index) {
            this.expandedIndex = this.expandedIndex === index ? null : index;
        },
        isExpanded(index) {
            return this.expandedIndex === index;
        },
        beforeEnter(el) {
            el.style.height = '0';
        },
        enter(el) {
            el.style.height = el.scrollHeight - 28 + 'px';
        },
        leave(el) {
            el.style.height = '0';
        }
    },
    mounted() {
        // Update head meta tags
        this.$emit('updateMeta', {})
        this.resizeVideoContainer();
        window.addEventListener('resize', this.resizeVideoContainer);
        this.getCards()
        this.getDpTypes()
        this.getOpenDpSteps()
        this.getInfoLinks()
        this.getFaq()

        new bootstrap.Carousel('#carousel').cycle()
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resizeVideoContainer);
    }
}

</script>

<style scoped>

    .video-container {
        width: 100%;
        position: relative;
        overflow: hidden;
    }

    .video-container iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }

    .video-section {
        background-image: url("../assets/img/video-bg.jpg");
        background-position: top center;
        color: #ffffff;
        min-height: 0;
    }

    .video-centered {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 600;
        text-transform: uppercase;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    section {
        display: flex;
        flex: auto;
        padding: 2em 0;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 80vh;
    }

    .answer {
        font-size: 16px;
        color: #5c5c5c;
        padding: 16px;
        padding-left: 52px;
        padding-bottom: 32px;
        background-color: #fff;
        overflow: hidden;
    }

    .accordion {
        background-color: #fff;
        color: #444;
        cursor: pointer;
        width: 100%;
        margin-bottom: 20px;
        text-align: left;
        border: none;
        outline: none;
        transition: 0.4s;
        box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2);
        border-radius: 4px;
    }

    .accordion .answer-wrapper {
        overflow: hidden;
    }

    .accordion.expanded .material-icons.drop {
        transform: rotate(90deg);
    }

    .slide-fade-enter-active, .slide-fade-leave-active {
        transition: height 0.5s ease;
    }

    .active,
    .accordion:hover .question-wrapper {
        background-color: #ebebeb;
    }

    .active p.question,
    .accordion:hover .question-wrapper p {
        color: #3d3d3d;
    }

    .banner {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 600px;
        color: #ffffff;
        background-color: #9082d9;
        background-position: center;
        background-size: cover;
        font-size: 1.5em;
    }

    .banner .row {
        max-width: 1200px;
    }

    .banner h3 {
        font-size: 1.75em;
    }

    .banner .subtitle-1 {
        font-size: 1.475em;
        line-height: 1.1;
    }

    .banner h4 {
        font-size: 1.275em;
    }

    .banner .btn-primary {
        background-color: #c697d4 !important;
    }

    .banner-bg-1 {
        background-image: url('../assets/img/banner-bg-1.jpg');
    }

    .banner-bg-2 {
        background-image: url('../assets/img/banner-bg-2.jpg');
    }

    .banner-bg-3 {
        background-image: url('../assets/img/banner-bg-3.jpg');
    }

    .banner-bg-4 {
        background-image: url('../assets/img/banner-bg-4.jpg');
    }

    .banner-btn {
        max-width: max-content;
        margin-top: .5rem;
        margin-left: auto;
        color: #132671;
        background-color: #ffbeed;
        font-weight: bold;
    }

    .banner-btn:hover {
        background-color: #d38abe;
    }

    .card-img {
        height: 80px;
        width: auto;
    }

    .card-img-1 {
        filter:
            drop-shadow(0 0 5px rgba(255, 255, 255, 0.8))
            drop-shadow(0 0 10px rgba(255, 80, 150, 0.8))
            drop-shadow(0 0 30px rgba(255, 80, 150, 0.8))
            drop-shadow(0 0 30px rgba(255, 80, 150, 0.8))
            drop-shadow(0 0 30px rgba(255, 80, 150, 0.8));
    }

    .card-img-2 {
        filter:
            drop-shadow(0 0 5px rgba(255, 255, 255, 0.8))
            drop-shadow(0 0 10px rgba(80, 230, 255, 0.8))
            drop-shadow(0 0 30px rgba(80, 230, 255, 0.8))
            drop-shadow(0 0 30px rgba(80, 230, 255, 0.8))
            drop-shadow(0 0 30px rgba(80, 230, 255, 0.8));
    }

    .card-img-3 {
        filter:
            drop-shadow(0 0 5px rgba(255, 255, 255, 0.8))
            drop-shadow(0 0 10px rgba(255, 80, 220, 0.8))
            drop-shadow(0 0 30px rgba(255, 80, 220, 0.8))
            drop-shadow(0 0 30px rgba(255, 80, 220, 0.8))
            drop-shadow(0 0 30px rgba(255, 80, 220, 0.8))
            drop-shadow(0 0 30px rgba(255, 80, 220, 0.8));
    }

    .card-img-4 {
        filter:
            drop-shadow(0 0 5px rgba(255, 255, 255, 0.8))
            drop-shadow(0 0 10px rgba(80, 230, 220, 0.8))
            drop-shadow(0 0 30px rgba(80, 230, 220, 0.8))
            drop-shadow(0 0 30px rgba(80, 230, 220, 0.8))
            drop-shadow(0 0 30px rgba(80, 230, 220, 0.8));
    }

    .cards-1 {
        color: #fff;
        background-color: rgba(255, 172, 205, 0.2);
        backdrop-filter: blur(10px) brightness(100%) contrast(150%) saturate(100%);
        filter: drop-shadow(1px 1px 2px rgba(255, 255, 255, 0.3));
        cursor: pointer;
        transition: .5s;
        -o-transition: .5s;
        -moz-transition: .5s;
        -webkit-transition: .5s;
    }

    .cards-1.active {
        background-color: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(20px) brightness(50%) contrast(100%) saturate(130%);
        transition: .5s;
        -o-transition: .5s;
        -moz-transition: .5s;
        -webkit-transition: .5s;
    }

    .cards-1 .card-body {
        padding: 2.5rem;
    }

    .cards-banner {
        min-height: 350px;
        height: 100%;
        width: auto;
        padding: 1em 0;
        background-size: cover;
        background-position: center;
    }

    .cards-section {
        background-image: url("../assets/img/section-1-bg.jpg");
        background-position: center;
        padding-top: 140px;
    }

    .carousel-item img {
        display: block;
        object-fit: cover;
        height: 100%;
        width: 100%;
    }

    .claims-check-section {
        display: block;
        min-height: auto;
        padding: 3em 0 0;
    }

    .faq-section {
        background-image: url("../assets/img/faq.jpg");
        padding: 60px 0;
        color: white;
        min-height: 0;
    }

    .infolinks-section {
        overflow-x: auto;
    }

    .info-title {
        margin-top: 1.5em;
        color: #3A3C40;
    }

    .instruction-section {
        background-image: url("../assets/img/section-3-bg.jpg");
        background-position: top center;
        color: #ffffff;
        min-height: 80vh;
    }

    .link-img {
        flex: 0 0 30%;
        max-width: 30%;
    }

    .links-heading {
        flex: 0 0 70%;
        max-width: 70%;
    }

    .links-text {
        color: #fff;
        font-size: clamp(16px, 2vw, 20px);
    }

    .nav-item {
        display: flex;
        align-items: center;
        padding: 0.5rem 1rem;
        margin-left: 0;
        background-color: #e5e3ff;
        color: #132671;
        text-decoration: none;
        align-items: center;
        justify-content: center;
    }

    .nav-item:hover {
        color: #9082d9;
        transition: .25s;
        -webkit-transition: .25s;
        -o-transition: .25s;
        -moz-transition: .25s;
    }

    .material-icons.drop {
        padding: 0 20px;
        font-size: 2rem;
        color: #d40119;
        transition: transform 0.5s ease-in-out;
    }

    .question-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: background-color 0.25s ease-in-out;
        height: fit-content;
    }

    .q-mark {
        color: #191970;
        padding: 18px 0 18px 18px;
    }

    .question {
        font-size: 20px;
        color: #5c5c5c;
        line-height: 1.1;
        padding: 10px 20px;
        margin-bottom: 0;
    }

    .section-heading {
        text-align: center;
        margin-bottom: 1em;
    }

    /* .slider-section {
        padding: 0;
        height: auto;
        min-height: auto;
        justify-content: center;
        display: flex;
        flex: auto;
        background-size: cover;
    } */

    /* .slider-text {
        position: absolute;
        width: 40%;
        top: 30%;
        left: 10%;
    }

    .slider-btn {
        max-width: max-content;
        margin-top: .5rem;
        margin-left: auto;
        color: #000;
        font-weight: 400;
        background-color: #ffd6ce;
        font-weight: bold;
    } */

    .step {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        margin-top: 1rem;
        margin-bottom: 5rem;
    }

    .steps-color {
        color: #fbabee;
    }

    .steps-img {
        width: 60px;
        height: auto;
        position: absolute;
        top: 100%;
        filter:
            drop-shadow(0 0 5px rgba(0, 140, 255, 0.8))
            drop-shadow(0 0 10px rgba(0, 140, 255, 0.8))
            drop-shadow(0 0 30px rgba(0, 140, 255, 0.8))
            drop-shadow(0 0 30px rgba(0, 140, 255, 0.8));
    }

    .text-control-1 h2 {
        margin-bottom: 20px;
    }

    .video-container {
        width: 100%;
        position: relative;
        overflow: hidden;
    }

    .video-container iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }

    .video-section {
        background-image: url("../assets/img/video-bg.jpg");
        background-position: top center;
        color: #ffffff;
        min-height: 0;
    }

    .video-centered {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media (min-width: 1400px) {
        .container {
            max-width: 1140px;
        }

        .video-section {
            padding: 5em;
        }
    }

    @media (max-width: 767px) {
        .banner {
            min-height: 740px;
            padding: 1em 0;
            text-align: center;
            font-size: 1.125em;
        }

        .banner img {
            width: auto;
            max-height: 340px;
            margin: auto;
        }

        .question {
            font-size: 18px;
        }

        .py-60{
            padding: 30px 0;
        }

        .mt-3{
            margin-top: 0.5em !important;
        }

        .margin-4{
            margin: 4%;
        }

        .width-92{
            width: 92%;
        }

        .accordion {
            margin: 4% !important;
            width: 92% !important;
        }
    }

    @media (max-width: 576px) {
        .card-text {
            font-size: 0.9rem;
        }
        
        .card-title {
            font-size: 1rem;
        }

        .cards-1 {
            height: auto;
            margin: .5rem 0;
        }

        .cards-banner {
            margin: .5rem 0;
            max-width: 100vw;
            height: auto;
        }

        /* .slider-text {
            width: 50%;
        }

        .slider-heading {
            font-size: 18px;
        }

        .fw-normal {
            font-size: 16px;
        } */

        .instruction-section h2 {
            font-size: 1.5rem;
            margin-bottom: 1rem;
        }

        .instruction-section p {
            font-size: 0.9rem;
            line-height: 1.4;
        }

        .link-img {
            height: 120px;
            width: auto;
        }

        .container {
            padding: 0%;
        }

        .video-section {
            padding: 0%;
        }
    }

    @media (max-height: 576px) {
        .cards-col {
            width: 50%;
        }
    }

</style>