<template>
    <div class="container-fluid">
        <Header />
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-md-3 text-center">
                <LoginForm
                    backTo="/"
                    :type="type"
                    :form-title="formTitle"
                    :phoneText="label_phone"
                    phoneMask="+7 (###) ###-##-##"
                    phoneLength="18"
                    :idnumText="label_id_num"
                    idnumMask="############"
                    idnumLength="12"
                    :noDataText="no_data"
                    :invalidDataText="invalid_data"
                    :submit-text="send"
                    :approveDescText="approve_desc"
                    :approveCodeText="approve_code"
                    approveCodeMask="######"
                    approveCodeLength="6"
                    :sendCodeText="send"
                    :secondBtnLink="getNcaLoginLink()"
                    :secondBtnText="btn_login"
                    @logged="logged" />
            </div>
        </div>
    </div>
</template>

<script>
    import LoginForm from 't3m-auth/src/components/Login.vue'
    import Header from './home/Header.vue'

    export default {
        name: 'LoginEdocument',
        data: () => ({
            type: 'edocument'
        }),
        computed: {
            formTitle() {
                return this.$t('login.sms_registration')
            },
            label_phone() {
                return this.$t('login.label_phone')
            },
            label_id_num() {
                return this.$t('login.label_id_num')
            },
            no_data() {
                return this.$t('login.no_data')
            },
            invalid_data() {
                return this.$t('login.invalid_data')
            },
            send() {
                return this.$t('login.send')
            },
            approve_desc() {
                return this.$t('login.approve_desc')
            },
            approve_code() {
                return this.$t('login.approve_code')
            },
            btn_login() {
                return this.$t('login.btn_login')
            }
        },
        components: {
            LoginForm,
            Header
        },
        methods: {
            getNcaLoginLink() {
                return '/login'
            },
            logged(data = {}) {
                if(process.env.VUE_APP_DEBUG) console.log('logged', data)
                this.$router.push(this.$store.state.redirects.afterLogin)
                this.$store.commit('clearRedirects')
            }
        }
    }
</script>

<style scoped>

    #app > .container-fluid {
        height: 100vh;
        background-image: url('../assets/img/legal-papers-sign06.png');
        background-size: cover;
    }
    
    @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }
    
        50% {
            background-position: 100% 50%;
        }
    
        100% {
            background-position: 0% 50%;
        }
    }

    .logo {
        margin: 0 auto 1rem;
    }

    .logo img {
        max-width: 180px;
    }

    @media (min-width: 1400px) {
        .logo {
            max-width: 1140px;
        }
    }

</style>