<template>
    <div id="content" class="col">
        <AmBreadcrumbs :showCurrentCrumb="true" />
        
        <div class="row align-items-stretch">
            <div class="col-md-8">
                <div class="shadow p-3">
                    <div class="messages" ref="messages" v-if="messages && messages.length && !loading.messages">
                        <table class="table table-bordered border-dark">
                            <thead>
                                <tr>
                                    <th>{{ $t('messages.table.column-1') }}</th>
                                    <th>{{ $t('messages.table.column-2') }}</th>
                                    <th>{{ $t('messages.table.column-3') }}</th>
                                    <th>{{ $t('messages.table.column-4') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="msg in messages ?? []" :key="msg.id" :class="msg.class">
                                    <td v-text="msg.from"></td>
                                    <td v-html="msg.text"></td>
                                    <td v-text="msg.human_date"></td>
                                    <td>
                                        <div class="message-buttons" v-if="msg.buttons ?? false">
                                            <button
                                                type="button"
                                                v-for="btn in msg.buttons"
                                                class="btn mb-2"
                                                :class="btn.class ?? ''"
                                                @click="messageBtnClick(btn.data)"
                                            >
                                                {{ btn.text }}
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="messages text-center" v-else>
                        <span class="message-text" v-show="loading.messages">
                            <span class="loading"></span>
                        </span>
                        <span class="message-text" v-show="!loading.messages">{{ $t('messages.no-messages') }}</span>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="shadow p-3 mb-3">
                    <h5 class="text-center">{{ $t('messages.actions') }}</h5>
                    <button
                        v-if="buttons.signSchedule"
                        type="button"
                        class="btn btn-primary w-100 mb-3"
                        :class="{disabled:loading.submit}, {'btn-loading':loading.submit}"
                        @click="signSchedule"
                    >
                        <div class="d-flex align-items-center text-start">
                            <i class="bi bi-pencil-fill fs-3 me-3"></i>
                            {{ $t('messages.sign-schedule') }}
                        </div>
                    </button>
                    <button
                        v-if="schedule.length"
                        type="button"
                        class="btn btn-primary w-100 mb-3"
                        @click="openSchedule"
                    >
                        <div class="d-flex align-items-center text-start">
                            <i class="bi bi-calendar2-week fs-3 me-3"></i>
                            {{ $t('messages.show-schedule') }}
                        </div>
                    </button>
                    <button
                        v-if="schedule.length && paymentLink"
                        type="button"
                        class="btn btn-success w-100 mb-3"
                        @click="paymentBySchedule"
                    >
                        <div class="d-flex align-items-center text-start">
                            <i class="bi bi-calendar2-week fs-3 me-3"></i>
                            {{ $t('messages.payment-by-schedule') }}
                        </div>
                    </button>
                    <button
                        v-if="schedule.length && fullPaymentLink"
                        type="button"
                        class="btn btn-success w-100"
                        @click="fullPaymentBySchedule"
                    >
                        <div class="d-flex align-items-center text-start">
                            <i class="bi bi-calendar2-range fs-3 me-3"></i>
                            {{ $t('messages.full-payment-by-schedule') }}
                        </div>
                    </button>
                    <div class="text-center my-3" v-if="schedule.length && paymentLink">
                        <small class="fst-italic text-muted" v-html="$t('claim.pay-block.tax-info')"></small>
                    </div>
                    <button
                        v-if="buttons.schedule"
                        type="button"
                        class="btn btn-primary w-100 mb-3"
                        :class="{disabled:loading.submit}, {'btn-loading':loading.submit}"
                        @click="openSchedule"
                    >
                        <div class="d-flex align-items-center text-start">
                            <i class="bi bi-calendar2-week fs-3 me-3"></i>
                            {{ $t('messages.new-schedule') }}
                        </div>
                    </button>
                    <button
                        v-if="buttons.decline"
                        type="button"
                        class="btn btn-danger w-100 mb-3"
                        :class="{disabled:loading.submit}, {'btn-loading':loading.submit}"
                        @click="openDecline"
                    >
                        <div class="d-flex align-items-center text-start">
                            <i class="bi bi-file-earmark-x fs-3 me-3"></i>
                            {{ $t('messages.decline') }}
                        </div>
                    </button>
                    <button
                        v-if="buttons.scheduleDecline1"
                        type="button"
                        class="btn btn-warning w-100 mb-3"
                        :class="{disabled:loading.submit}, {'btn-loading':loading.submit}"
                        @click="openNewSchedule"
                    >
                        <div class="d-flex align-items-center text-start">
                            <i class="bi bi-calendar-plus fs-3 me-3"></i>
                            {{ $t('messages.decline-new-schedule') }}
                        </div>
                    </button>
                    <button
                        v-if="buttons.scheduleDecline2"
                        type="button"
                        class="btn btn-danger w-100 mb-3"
                        :class="{disabled:loading.submit}, {'btn-loading':loading.submit}"
                        @click="openScheduleDecline2"
                    >
                        <div class="d-flex align-items-center text-start">
                            <i class="bi bi-calendar-x fs-3 me-3"></i>
                            {{ $t('messages.decline-schedule') }}
                        </div>
                    </button>
                    <button
                        type="button"
                        class="btn btn-secondary w-100"
                        @click="backToClaim"
                    >
                        <div class="d-flex align-items-center">
                            <i class="bi bi-arrow-left fs-3 me-3"></i>
                            {{ $t('messages.back-to-claim') }}
                        </div>
                    </button>
                </div>
                <div class="shadow p-3 text-center">
                    <h5>{{ $t('messages.contact') }}</h5>
                    <button
                        type="button"
                        class="btn btn-secondary"
                        @click="phoneCall"
                    >
                        <i class="bi bi-telephone-fill me-2"></i>
                        {{ $t('messages.phone-call') }}
                    </button>
                    <button
                        type="button"
                        class="btn btn-secondary"
                        @click="writeWhatsapp"
                    >
                        <i class="bi bi-whatsapp me-2"></i>
                        {{ $t('messages.whatsapp') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <PaymentScheduleModal
        :amount="amount"
        :only-show="!!schedule.length"
        :schedule="schedule"
        :can-payment="!!paymentLink"
        @changed="scheduleChanged"
        @payment="paymentBySchedule"
    />
    <DeclineModal
        id="declineModal"
        :title="$t('messages.decline-title')"
        :text="declineText"
        @confirmed="declineConfirmed"
    />
    <DeclineModal
        id="scheduleDecline1Modal"
        :title="$t('messages.schedule-decline-1-title')"
        :text="scheduleDecline1Text"
        @confirmed="scheduleDecline1Confirmed"
    />
    <DeclineModal
        id="scheduleDecline2Modal"
        :title="$t('messages.decline-schedule')"
        :text="scheduleDecline2Text"
        @confirmed="declineScheduleConfirmed"
    />
    <SignModal
        :user-idnum="userIdnum"
        :user-phone="userPhone"
        :show-edocument="canSignByEdocument"
        :before-send-code-desc="beforeSendCodeDesc"
        :send-code-desc="sendCodeDesc"
        @signed="signed"
    />
</template>

<script>
import { Form } from 'vee-validate'
import Input from '../form/Input.vue'
import Select from '../form/Select2.vue'

import PaymentScheduleModal from './PaymentScheduleModal.vue'
import DeclineModal from './DeclineModal.vue'
import SignModal from '../cabinet/SignModal.vue'

export default {
    components: {
        Form,
        Input,
        Select,
        PaymentScheduleModal,
        DeclineModal,
        SignModal
    },
    data: () => ({
        loading: {
            messages: false,
            submit: false
        },
        text: '',
        messages: [],
        templates: {},
        buttons: {
            decline: false,
            schedule: false,
            scheduleDecline1: false,
            scheduleDecline2: false
        },
        amount: 0,
        schedule: [],
        paymentLink: '',
        fullPaymentLink: '',
        password: '',
        action: '',
        canSignByEdocument: false,
        userIdnum: '',
        userPhone: '',
        beforeSendCodeDesc: '',
        sendCodeDesc: '',
        phoneCallLink: '',
        whatsappLink: ''
    }),
    computed: {
        declineText() {
            return this.templates.decline ?? ''
        },
        scheduleDecline1Text() {
            return this.templates.scheduleDecline1 ?? ''
        },
        scheduleDecline2Text() {
            return this.templates.scheduleDecline2 ?? ''
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        async getData() {
            let request = await this.$api.getMessages(this.$route.params.slug)

            let messages = request.data ?? []
            let templates = request.templates ?? []

            for(let i in messages) {
                if(messages[i].schedule ?? false) {
                    let schedule = messages[i].schedule
                    messages[i].text = this.$t('messages.schedule-message', { date: schedule.date, months: schedule.month })
                }
            }

            this.messages = messages
            this.templates = templates
            this.amount = request.amount ?? 0
            this.paymentLink = request.paymentLink
            this.fullPaymentLink = request.fullPaymentLink
            this.schedule = request.schedule
            this.phoneCallLink = request.phoneCallLink ?? ''
            this.whatsappLink = request.whatsappLink ?? ''
            this.canSignByEdocument = request.canSignBySms ?? false
            this.userIdnum = request.userIdnum ?? ''
            this.userPhone = request.userPhone ?? ''
            this.sendCodeDesc = request.sendCodeDesc ?? ''
            this.beforeSendCodeDesc = request.beforeSendCodeDesc ?? ''

            if(request.buttonsStatus ?? false) this.buttons = request.buttonsStatus
        },
        async send(action, data, file, password) {
            if(this.loading.submit) return

            this.loading.submit = true

            let formData = new FormData()
            formData.append('action', action)
            formData.append('data', data)
            formData.append('password', password)
            formData.append('file', file, file.name)

            let request = await this.$api.addMessage(this.$route.params.slug, formData)

            this.loading.submit = false

            if(request.result === 'SUCCESS') {
                await this.getData()
                
                this.clear()

                this.$notify({
                    title: this.$t('notify.types.success'),
                    text: this.$t('messages.notify.message-sended'),
                    type: 'success'
                })
            } else {
                this.$notify({
                    title: this.$t('notify.types.error'),
                    text: this.$t('messages.notify.try-again'),
                    type: 'error'
                })
            }
        },
        signed({ file = '', password = '', key = '' }) {
            if(!this.action) {
                this.$notify({
                    title: this.$t('notify.types.error'),
                    text: this.$t('messages.notify.try-again'),
                    type: 'error'
                })
            }

            if(file && password) {
                this.submit(this.action, { key: file, password })
            } else if(key) {
                this.submit(this.action, { key: key })
            } else {
                this.$notify({
                    title: this.$t('notify.types.error'),
                    text: this.$t('messages.notify.need-sign'),
                    type: 'error'
                })
            }
        },
        async submit(action, { key = '', password = '' }) {
            let data = {
                action: action
            }

            if(action == 'schedule' || action == 'new-schedule') {
                data.scheduleMonth = this.schedule.month
                data.scheduleDate = this.schedule.firstPaymentDate
                data.initialPayment = this.schedule.initialPayment
            }

            if(password) {
                data.filekey = key
                data.password = password
            } else {
                data.key = key
            }

            await this.messageAction(data)
        },
        clear() {
            this.text = ''
            this.password = ''

            this.$refs.file.value = null
            this.$refs.form.resetForm()
        },
        openDecline() {
            this.$store.commit('modalShow', { id: 'declineModal' })
        },
        async declineConfirmed() {
            this.openSignModal('decline')
        },
        openScheduleDecline1() {
            this.$store.commit('modalShow', { id: 'scheduleDecline1Modal' })
        },
        async scheduleDecline1Confirmed() {
            
        },
        openScheduleDecline2() {
            this.$store.commit('modalShow', { id: 'scheduleDecline2Modal' })
        },
        async declineScheduleConfirmed() {
            this.openSignModal('decline-schedule')
        },
        signSchedule() {
            this.action = 'sign-schedule'

            this.openSignModal(this.action)
        },
        openSchedule() {
            this.$store.commit('modalShow', { id: 'paymentScheduleModal' })
        },
        openNewSchedule() {
            this.schedule = []
            this.action = 'new-schedule'
            this.$store.commit('modalShow', { id: 'paymentScheduleModal' })
        },
        scheduleChanged(data) {
            this.schedule = data

            let action = 'schedule'
            if(this.action == 'new-schedule') action = this.action

            this.openSignModal(action)
        },
        openSignModal(action) {
            this.action = action
            this.$store.commit('modalShow', { id: 'signModal'})
        },
        async messageAction(data) {
            this.loading.submit = true

            let formData = new FormData()

            for(let i in data) {
                if(i == 'filekey') {
                    formData.append('file', data[i], data[i].name)
                } else {
                    formData.append(i, data[i])
                }
            }

            let request = await this.$api.messageAction(this.$route.params.slug, formData)

            await this.getData()

            this.loading.submit = false
        },
        messageBtnClick(data) {
            let { link = '' } = data

            if(link) window.open(link, '_blank')
        },
        showSchedule() {
            this.$store.commit('modalShow', { id: 'paymentScheduleModal' })
        },
        paymentBySchedule() {
            if(this.paymentLink) window.open(this.paymentLink, '_blank')
        },
        fullPaymentBySchedule() {
            if(this.fullPaymentLink) window.open(this.fullPaymentLink, '_blank')
        },
        scrollToLastMessage() {
            // this.$refs.messagesBottom?.scrollIntoView({ behavior: 'smooth' })
            // const el = this.$refs.messages
            // el.scrollTop = el.scrollHeight - el.clientHeight
        },
        backToClaim() {
            this.$router.push(`/claims/${this.$route.params.slug}`)
        },
        phoneCall() {
            if(this.phoneCallLink) window.open(this.phoneCallLink, '_blank')
        },
        writeWhatsapp() {
            if(this.whatsappLink) window.open(this.whatsappLink, '_blank')
        }
    }
}
</script>

<style scoped>
.editor {
    margin-bottom: 1em;
    padding: .5em 1em;
    border: 1px solid #3a3077;
    outline: none;
}

.nav-tabs {
    margin-bottom: 1em;
}

.nav-item {
    flex: auto;
}

.nav-item .nav-link {
    width: 100%;
    padding: 1em;
    color: #261D58;
    background-color: #E1E1EC;
    border: none;
}

.nav-item:first-child .nav-link {
    border-radius: 1em 0 0 1em;
}

.nav-item:last-child .nav-link {
    border-radius: 0 1em 1em 0;
}

.nav-item .nav-link.active {
    width: 100%;
    color: #FFFFFF;
    background-color: #BFBCF4;
}

.messages {
    max-height: calc(100vh - 200px);
    padding: 0 20px;
    overflow-x: hidden;
    overflow-y: auto;
}

.message-template-schedule,
.message-template-decline-schedule,
.message-template-decline,
.message-template-sign {
    color: #aaaaaa;
    background-color: #dddddd;
}

.message-template-schedule:nth-child(1) {
    color: #000000;
    background-color: rgb(236, 253, 232);
}

.message-template-decline-schedule:nth-child(1) {
    color: #000000;
    background-color: rgb(247, 242, 226);
}

.message-template-decline:nth-child(1) {
    color: #000000;
    background-color: rgb(251, 225, 225);
}

.message-template-sign:nth-child(1) {
    color: #000000;
    background-color: rgb(233, 233, 251);
}

.message-buttons {
    text-align: center;
}

.message-from {
    display: block;
    margin-bottom: 1em;
    color: #3a3077;
}

.message-text {
    display: block;
}

.message-text > p {
    margin-bottom: .5em;
}

.message-date {
    display: block;
    text-align: right;
    color: #BFBCF4;
    font-size: .75em;
}
</style>