const { translate } = require('./locale')

async function addressToStr(address, lang = 'ru') {
    let str = ''
    let {
        country = '',
        region,
        locality,
        localityType,
        street,
        buildingNumber,
        floor,
        apartment,
        extra = ''
    } = address

    if(!country || !isNaN(country)) country = await getFromDB(country, 'countries')
    if(!isNaN(region)) region = await getFromDB(region, 'regions')
    if(!isNaN(locality)) locality = await getFromDB(locality, 'localities')

    // localityType: { title: locality.type.title, short: locality.type.short }

    let streetLocale = translate('address.street', lang),
        buildingNumberLocale = translate('address.buildingNumber', lang),
        floorLocale = translate('address.floor', lang),
        apartmentLocale = translate('address.apartment', lang)

    if(country) str += country
    if(region) str += ', ' + region
    if(locality) str += ', ' + locality
    if(street) str += ', ' + streetLocale + ' ' + street
    if(buildingNumber) str += ', ' + buildingNumberLocale + ' ' + buildingNumber
    // if(floor) str += ', ' + floor + ' ' + floorLocale
    if(apartment) str += ', ' + apartmentLocale + ' ' + apartment
    if(extra) str += ', ' + extra

    return clearDublicateLocalities(str)
}

function shortAddress(address) {
    let str = ''
    let {
        street,
        buildingNumber,
        apartment,
        extra = ''
    } = address

    let arr = Object.values(address)

    if(street) str += street
    if(buildingNumber) str += ', ' + buildingNumber
    if(apartment) str += ', ' + apartment
    if(extra) str += ', ' + extra

    str = arr.join(', ')

    return clearDublicateLocalities(str)
}

function clearDublicateLocalities(str) {
    const arr = str.split(', ')
    return arr.filter((value, index) => arr.indexOf(value) === index).join(', ')
}

async function getFromDB(item, table) {
    let q = await db.selectOne(table, { filter: { id: item } })

    if(q) return q.title
    return item
}

async function getZipcode(localityId) {
    const t = 'localities'
    let locality = await db.selectOne(t, {
        filter: { id: localityId }
    })

    if(locality && locality.zip_code) return locality.zip_code
    return null
}

async function getNearestZipcode(localityId) {
    const locality = await db.selectOne('localities', {
        filter: { id: localityId }
    })

    if(!locality) return null

    if(locality.zip_code) return locality.zip_code

    const [relationsResult] = await db.execute(
        `SELECT l.zip_code
            FROM locality_relations lr
            LEFT JOIN localities l ON lr.parent_id = l.id OR lr.child_id = l.id
            WHERE (lr.parent_id = ? OR lr.child_id = ?) AND l.zip_code IS NOT NULL
            LIMIT 1`,
        [localityId, localityId]
    )

    if(relationsResult.zip_code) return relationsResult.zip_code

    const [regionResult] = await db.execute(
        `SELECT zip_code
            FROM localities
            WHERE region_id = ? AND zip_code != ''
            LIMIT 1`,
        [locality.region_id]
    )

    if(regionResult.zip_code) return regionResult.zip_code

    return null
}

async function checkLocalityByKato(locality) {
    if(!locality) return false

    let q = await db.selectOne('localities', { filter: { kato_code: locality } })

    return q?.id || false
}

module.exports = {
    addressToStr,
    shortAddress,
    getFromDB,
    getNearestZipcode,
    getZipcode,
    checkLocalityByKato
}