import { createApp } from 'vue'
import { createStore } from 'vuex'
import { createI18n } from 'vue-i18n'
import axios from 'axios'
import bootstrap from 'bootstrap/js/index.umd'
import Notifications from '@kyvg/vue3-notification'
import breadcrumbs from 'vue-3-breadcrumbs'
import Vue3EasyDataTable from 'vue3-easy-data-table'
import { createGtm } from '@gtm-support/vue-gtm';

import _User from '../libs/user'
import _Pagination from '../libs/pagination'
import _Text from '../libs/text'
import _Debt from '../libs/debt'
import _Cookies from '../libs/cookies'

import API from './api'
import router from './router'

import App from './App.vue'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'mdb-vue-ui-kit/css/mdb.min.css'
import 'charts.css'

const store = createStore({
    state() {
        return {
            auth: false,
            isAuth: false,
            loggedNow: false,
            redirects: {
                afterLogin: '/cabinet'
            },
            claim: {},
            counters: {
                inbox: 0,
                outbox: 0,
                balance: 0
            },
            modal: {
                id: 'myModal',
                status: 'hid',
                title: '',
                body: '',
                buttons: [],
                size: null,
                result: null
            },
            claimTextModal: {
                id: 'claimTextModal',
                status: 'hid',
                title: '',
                body: '',
                buttons: [],
                size: null
            },
            modals: []
        }
    },
    mutations: {
        login(state, data) {
            state.isAuth = true
            state.user = data

            // need edit
            saveStorage('isAuth', {
                status: state.isAuth
            })
            saveStorage('user', state.user)

            // after sidebar mounted,
            // then loggedNow = false
            state.loggedNow = true
        },
        setAuth(state, data) {
            state.auth = data

            saveStorage('auth', state.auth)
        },
        setRedirects(state, data) {
            for(let i in data) {
                state.redirects[i] = data[i]
            }
        },
        clearRedirects(state) {
            state.redirects = {
                afterLogin: '/cabinet'
            }
        },
        logout(state) {
            state.isAuth = false
            delete state.user

            clearStorage('auth')
            clearStorage('isAuth')
            clearStorage('user')
        },
        saveUser(state) {
            saveStorage('user', state.user)
        },
        getClaim(state) {
            let savedClaim = getStorage('claim')

            if(savedClaim) state.claim = savedClaim

            return state.claim
        },
        setClaim(state, data) {
            state.claim = data
            saveStorage('claim', state.claim)
        },
        clearClaim(state) {
            state.claim = {}
            clearStorage('claim')
        },
        updateCounter(state, data) {
            let { type, value = 0 } = data

            if(process.env.VUE_APP_DEBUG) console.log('updateCounter', data)
            if(process.env.VUE_APP_DEBUG) console.log('updateCounter - counters', state.counters)

            state.counters[type] = value
        },
        getCounter(state, data) {
            let { type } = data

            return state.counters[type]
        },
        showModal(state, data) {
            const myModal = new bootstrap.Modal(`#${state.modal.id}`)

            if(state.modal.status == 'shown') myModal.hide()

            let { title = '', body = '', buttons = [], size = null } = data

            state.modal.title = title
            state.modal.body = body
            state.modal.buttons = buttons
            state.modal.size = size
            state.modal.status = 'shown'
            
            myModal.show()
        },
        hideModal(state) {
            state.modal.title = ''
            state.modal.body = ''
            state.modal.buttons = []
            state.modal.size = null
            state.modal.status = 'hid'

            const myModal = new bootstrap.Modal(`#${state.modal.id}`)
            myModal.hide()
        },
        setModalResult(state, data) {
            state.modal.result = data
            state.commit('hideModal')
        },
        modalShow(state, data) {
            const modal = new bootstrap.Modal(`#${data.id}`)
            modal.show()
        },
        modalHide(state, data) {
            const modal = new bootstrap.Modal(`#${data.id}`)
            if(process.env.VUE_APP_DEBUG) console.log('modalHide data', data)
            modal.hide()
        }
    }
})

const i18n = createI18n({
    locale: 'ru',
    fallbackLocale: 'ru',
    messages: {
        'ru': require('./common/locales/ru.json'),
        'en': require('./common/locales/en.json'),
        'kk': require('./common/locales/kk.json')
    }
})

const app = createApp(App)

window.axios = axios
window.bootstrap = bootstrap

let _libs = {
    user: _User,
    pagination: _Pagination,
    text: _Text,
    debt: _Debt,
    cookies: _Cookies
}

app.config.globalProperties.$libs = _libs
window.axios = axios
window.bootstrap = bootstrap
app.config.globalProperties.$api = API
app.config.globalProperties.updateMetaTags = updateHeadTags
app.config.globalProperties.currency = '₸'

app.use(i18n)
app.use(bootstrap)
app.use(store)

router.beforeEach(async (to, from, next) => {
    let auth = await getAuth()

    if(!auth) {
        store.commit('logout')
    }

    window.scrollTo(0, 0)

    cleanupMetaTags()
    
    if(!store.state.user && getStorage('user')) store.state.user = getStorage('user')

    if(auth && to.path == '/login') {
        next('/cabinet')
    }

    if(to.matched.some(r => r.meta.requiresAuth)) {
        // check auth
        if(!auth) {
            // if user need login
            next({ path: '/login' })
        } else {
            // logged in
            next()
        }
    } else {
        // auth not required
        next()
    }
})

// set default document title
router.afterEach((to, from, next) => {
    // document.title = to.meta.title || getDefaultTitle()
})

app.use(router)
app.use(bootstrap)
app.use(Notifications)
app.use(breadcrumbs, {
    includeComponent: true // {boolean} [includeComponent=false] - Include global breadcrumbs component or not
})

// google tag manager
if(process.env.VUE_APP_GTM_ID ?? false) {
    app.use(
        createGtm({
            id: process.env.VUE_APP_GTM_ID,
            vueRouter: router
        })
    )
}

app.component('EasyDataTable', Vue3EasyDataTable)
app.mount('#app')

async function getAuth() {
    let currentVersion = process.env.VUE_APP_VERSION
    let auth = false

    let url = process.env.VUE_APP_API_URL + '/session'

    let r = await axios.get(url, {
        withCredentials: true
    }).then((response) => {
        return response.data
    })

    if(r) {
        auth = {
            status: r.data.isAuth ?? false,
            version: currentVersion
        }
    }

    return auth ? auth.status : false
}

function checkFields(obj = {}) {
    for(let i in obj) {
        if(obj[i] === '') return false
    }

    return true
}

function saveStorage(key, data) {
    localStorage.setItem(key, JSON.stringify(data))
}

function getStorage(key) {
    if(localStorage.getItem(key)) return JSON.parse(localStorage.getItem(key))

    return null
}

function clearStorage(key = false) {
    if(key) {
        localStorage.removeItem(key)
    } else {
        localStorage.clear()
    }
}

function updateHeadTags(data) {
    if(!data || typeof data !== 'object') {
        console.error('Invalid data')
        return
    }

    const head = document.head

    if(data.title && typeof data.title === 'string') {
        document.title = data.title
    }

    if(data.meta && Array.isArray(data.meta)) {
        data.meta.forEach(tag => {
            if(tag.name && tag.content) {
                let existingMetaTag = head.querySelector(`meta[name="${tag.name}"]`)

                if(existingMetaTag) {
                    existingMetaTag.setAttribute('content', tag.content)
                } else {
                    let newMetaTag = document.createElement('meta')

                    newMetaTag.setAttribute('name', tag.name)
                    newMetaTag.setAttribute('content', tag.content)

                    head.appendChild(newMetaTag)
                }
            } else if(tag.property && tag.content) {
                let existingMetaTag = head.querySelector(`meta[property="${tag.property}"]`)

                if(existingMetaTag) {
                    existingMetaTag.setAttribute('content', tag.content)
                } else {
                    let newMetaTag = document.createElement('meta')

                    newMetaTag.setAttribute('property', tag.property)
                    newMetaTag.setAttribute('content', tag.content)

                    head.appendChild(newMetaTag)
                }
            }
        })
    }
}

// clean up description meta tags when there are none
function cleanupMetaTags() {
    const dynamicMetaTags = document.querySelectorAll('meta[name="description"]')

    dynamicMetaTags.forEach((metaTag) => {
        metaTag.remove()
    })
}

function getDefaultTitle() {
    return process.env.VUE_APP_C_MODE + 'KDP'
}