<template>
    <div id="content" class="col">
        <div class="row align-items-center my-2 py-2 text-center" v-for="user in users" :key="user.id">
            <div class="col-sm-2">
                <span>{{ user.id }}</span>
            </div>
            <div class="col-sm-4">
                <span>{{ user.login }}</span>
            </div>
            <div class="col-sm-3">
                <span>{{ user.role }}</span>
            </div>
            <div class="col-sm-3">
                <div class="btn-group" role="group" aria-label="Basic example">
                    <button type="button" @click="editUser(user.id)" class="btn btn-primary btn-sm">
                        <i class="bi bi-pencil"></i>
                    </button>
                    <button type="button" @click="deleteUser(user.id)" class="btn btn-danger btn-sm">
                        <i class="bi bi-trash"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Panel from './cabinet/Panel.vue'

    export default {
        data: () => ({
            title: 'Пользователи',
            users: []
        }),
        components: {
            Panel
        },
        mounted() {
            // set page title
            this.$emit('setPageTitle', title)

            this.getUsers()
        },
        methods: {
            langChange() {
                this.$emit('setPageTitle', title)
            },
            getUsers() {
                let url = new URL(process.env.VUE_APP_API_URL+'/users'),
                    params = { role_id: this.$store.state.user.role_id }

                url.search = new URLSearchParams(params).toString()

                fetch(url, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' }
                }).then(response => response).then(async res => {
                    let r = await res.json()

                    if(r.result === 'SUCCESS') {
                        this.$notify({
                            title: 'Успех',
                            text: r.message,
                            type: 'success'
                        })
                        this.users = Array.isArray(r.data) ? r.data : [r.data]
                    } else {
                        this.$notify({
                            title: 'Ошибка',
                            text: r.message,
                            type: 'error'
                        })
                        this.users = []
                    }
                    
                    this.$notify('Список пользователей обновлен')
                    if(process.env.VUE_APP_DEBUG) console.log('result', r)
                })
            },
            editUser(id) {
                this.$router.push('/users/' + id)
            },
            deleteUser(id) {
                const url = process.env.VUE_APP_API_URL+'/users/'
                fetch(url + id, {
                    method: 'DELETE',
                    headers: { 'Content-Type': 'application/json' }
                }).then(response => response).then(async res => {
                    let r = await res.json()

                    if(r.result === 'SUCCESS') {
                        this.$notify('Пользователь успешно удален')
                        this.getUsers()
                    }

                    if(r.user_id == this.$store.state.user.id) {
                        this.$store.commit('logout')
                        this.$router.push('/login')
                    }

                    if(process.env.VUE_APP_DEBUG) console.log('result', r)
                })
            }
        }
    }
</script>

<style scoped>

    #app {
        display: flex;
        height: 100vh;
    }
    #content {
        overflow-y: auto;
    }
</style>