<template>
    <div class="modal fade" :id="id" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content p-3 text-bg-light">
                <div class="modal-header d-flex align-items-center">
                    <h5 class="mb-0">{{ $t('payment-schedule-modal.title') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" ref="closeBtn" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row" v-if="!onlyShow">
                        <div class="col-md-6">
                            <label for="">
                                {{ $t('payment-schedule-modal.initial-payment') }}
                            </label>
                        </div>
                        <div class="col-md-6">
                            <Input
                                v-model="initialPayment"
                                name="amount"
                                type="number"
                                :placeholder="$t('payment-schedule-modal.initial-payment')"
                                :min="50"
                                :validations="['required', 'min(50)', `max(${this.amount})`]"
                                @change="onFormChange"
                            />
                        </div>
                        <div class="col-md-4">
                            <Select
                                v-model="month"
                                :name="'month'"
                                type="number"
                                :label="$t('payment-schedule-modal.months')"
                                :placeholder="$t('payment-schedule-modal.months-placeholder')"
                                :options="months"
                                :parent="'#' + id"
                                :search="false"
                                @change="onFormChange"
                            />
                        </div>
                        <div class="col-md-4">
                            <Input
                                v-model="firstPaymentDate"
                                name="firstPaymentDate"
                                type="date"
                                :label="$t('payment-schedule-modal.first-payment')"
                                :placeholder="$t('payment-schedule-modal.first-payment-placeholder')"
                                :min="getTodayDate"
                                :validations="['date']"
                                @change="onFormChange"
                            />
                        </div>
                        <div class="col-md-4">
                            <Input
                                v-model="_amount"
                                name="amount"
                                :label="$t('payment-schedule-modal.total-amount')"
                                :placeholder="$t('payment-schedule-modal.total-amount-placeholder')"
                                :disabled="true"
                            />
                        </div>
                        <div class="col-md-12 text-center" v-if="paymentSchedule.length">
                            <h5>{{ $t('payment-schedule-modal.payment-schedule') }}</h5>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>{{ $t('payment-schedule-modal.date') }}</th>
                                        <th>{{ $t('payment-schedule-modal.amount') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in paymentSchedule">
                                        <td>{{ item.date }}</td>
                                        <td>{{ item.amount }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row" v-else>
                        <div class="col-md-12">
                            <table class="table table-bordered border-dark">
                                <thead>
                                    <tr>
                                        <th>{{ $t('payment-schedule-modal.date') }}</th>
                                        <th>{{ $t('payment-schedule-modal.amount') }}</th>
                                        <th>{{ $t('payment-schedule-modal.status') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in schedule">
                                        <td>{{ item.human_date ? item.human_date : $t('payment-schedule-modal.initial-payment') }}</td>
                                        <td>{{ item.amount }}</td>
                                        <td>{{ item.human_status }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <small v-if="canPayment" class="fst-italic text-muted mb-2 w-100">{{ $t('claim.pay-block.tax-info') }}</small>
                    <button
                        v-if="!onlyShow"
                        type="submit"
                        class="btn btn-primary mt-3 ms-3"
                        @click="submit"
                    >
                        {{ $t('payment-schedule-modal.save') }}
                    </button>
                    <button
                        v-else-if="canPayment"
                        type="button"
                        class="btn btn-primary mt-3 ms-3"
                        @click="paymentBySchedule"
                    >
                        {{ $t('payment-schedule-modal.payment-by-schedule') }}
                    </button>
                    <button class="btn btn-secondary mt-3 ms-3" data-bs-dismiss="modal">{{ $t('close') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Select from '../form/Select2.vue'
import Input from '../form/Input.vue'

export default {
    components: {
        Select,
        Input
    },
    data: () => ({
        id: 'paymentScheduleModal',
        loading: false,
        initialPayment: 0,
        month: 3,
        months: [
            { id: 3, text: 3 },
            { id: 6, text: 6 },
            { id: 12, text: 12 }
        ],
        firstPaymentDate: '',
        errorMsg: ''
    }),
    props: {
        recipientId: {
            type: Number,
            default: null
        },
        amount: {
            type: Number,
            default: 0
        },
        onlyShow: {
            type: Boolean,
            default: false
        },
        canPayment: {
            type: Boolean,
            default: false
        },
        schedule: {
            type: Array,
            default: []
        }
    },
    computed: {
        _amount() {
            let a = this.amount
            if(this.initialPayment) {
                if((a - this.initialPayment) < 0) {
                    this.initialPayment = 0
                }

                a = a - this.initialPayment
            }
            return a
        },
        paymentSchedule() {
            let arr = []

            if(this.amount && this.month && this.firstPaymentDate) {
                arr = this.calculatePaymentSchedule(this.amount, this.month, this.firstPaymentDate, this.initialPayment)
            }

            return arr
        }
    },
    methods: {
        calculatePaymentSchedule(amount, months, start = '', initialPayment = 0) {
            let arr = []

            if(initialPayment) amount = amount - initialPayment

            let amountPerMonth = Math.floor(amount / months)
            let residualAmount = amount
            
            for(let i = 0; i < months; i++) {
                let startDate = new Date(start)
                let paymentDate = new Date(startDate.setMonth(startDate.getMonth() + i))

                residualAmount = residualAmount - amountPerMonth
                
                if(i == (months - 1)) {
                    amountPerMonth = amountPerMonth + residualAmount
                }

                arr.push({
                    amount: amountPerMonth,
                    date: paymentDate.toISOString().slice(0,10)
                })
            }

            return arr
        },
        onFormChange() {
            
        },
        async submit() {
            if(!this.month || !this.firstPaymentDate) return

            this.$emit('changed', { month: this.month, firstPaymentDate: this.firstPaymentDate, initialPayment: this.initialPayment })
            this.clear()
            this.close()
        },
        paymentBySchedule() {
            this.$emit('payment')

            this.clear()
            this.close()
        },
        getTodayDate() {
            let today = new Date(),
                d = today.getDate(),
                m = today.getMonth() + 1,
                y = today.getFullYear()

            if(d <= 9) d = '0' + d
            if(m <= 9) m = '0' + m

            return y + '-' + m + '-' + d
        },
        clear() {
            this.month = this.months[0]
        },
        close() {
            this.$refs.closeBtn.click()
        }
    }
}
</script>