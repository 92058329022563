<template>
    <div class="modal fade" :id="id" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content text-bg-light">
                <div class="modal-header">
                    {{ $t('recipient.title') }}
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body px-5">
                    <table class="table table-striped">
                        <tbody>
                            <tr>
                                <td>{{ recipientType == 'company' ? $t('recipient.recipient-card.bin') : $t('recipient.recipient-card.iin') }}</td>
                                <td>{{ id_no }}</td>
                                <td></td>
                            </tr>
                            <tr v-if="recipientType==='person'">
                                <td>{{ $t('recipient.recipient-card.lastName') }}</td>
                                <td>{{ lastName }}</td>
                                <td></td>
                            </tr>
                            <tr v-if="recipientType==='person'">
                                <td>{{ $t('recipient.recipient-card.firstName') }}</td>
                                <td>{{ firstName }}</td>
                                <td></td>
                            </tr>
                            <tr v-if="recipientType==='person'">
                                <td>{{ $t('recipient.recipient-card.patronymic') }}</td>
                                <td>{{ patronymic }}</td>
                                <td></td>
                            </tr>
                            <tr v-if="recipientType==='company'">
                                <td>{{ $t('recipient.recipient-card.companyName') }}</td>
                                <td>{{ companyName }}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>{{ $t('recipient.recipient-card.email') }}</td>
                                <td>
                                    <div v-for="e in email">
                                        {{ e }}
                                    </div>
                                </td>
                                <td>
                                    <a class="btn btn-secondary" href="#" @click="addContactModal('email', recipient.id)">{{ $t('claims.add.add-email') }}</a>
                                </td>
                            </tr>
                            <tr>
                                <td>{{ $t('recipient.recipient-card.phone') }}</td>
                                <td>
                                    <div v-for="p in phone">
                                        {{ p }}
                                    </div>
                                </td>
                                <td>
                                    <a class="btn btn-secondary" href="#" @click="addContactModal('phone', recipient.id)">{{ $t('claims.add.add-phone') }}</a>
                                </td>
                            </tr>
                            <tr>
                                <td>{{ $t('recipient.recipient-card.addresses') }} ({{ address.length }})</td>
                                <td>
                                    <div v-for="a in address">
                                        {{ a }}
                                    </div>
                                    <div class="accordion" id="accordionAddress" v-if="false">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAddress" aria-expanded="false" aria-controls="collapseAddress">
                                                    {{ $t('recipient.recipient-card.addresses') }} ({{ address.length }})
                                                </button>
                                            </h2>
                                            <div id="collapseAddress" class="accordion-collapse collapse" data-bs-parent="#accordionAddress">
                                                <div class="accordion-body">
                                                    <table class="table table-sm table-borderless">
                                                        <tbody>
                                                            <tr v-for="a in address">
                                                                <td>
                                                                    {{ a }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <a class="btn btn-secondary" ref="addAddressBtn" href="#" @click="addContactModal('address', recipient.id)">{{ $t('claims.add.add-address') }}</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="modal-footer p-3">

                </div>
            </div>
        </div>
    </div>
    <AddRecipientContactsModal
        ref="addRecipientContactsModal"
        @added="added"
    />
</template>

<script>

    import { shortenCompanyName } from '../../../libs/text';
    import AddRecipientContactsModal from './modals/AddRecipientContactsModal.vue'

    export default {
        name: 'recipientCardModal',
        data: () => ({
            id: 'recipientCardModal',
            contactModalType: ''
        }),
        props: {
            recipient: {
                type: Object,
                default: {}
            }
        },
        components: {
            AddRecipientContactsModal
        },
        computed: {
            recipientType: {
                get() {
                    if(this.recipient.company_name) return 'company'
                    if(!this.recipient.company_name) return 'person'
                }
            },
            firstName: {
                get() {
                    if(this.recipient._edit) return this.recipient._edit.firstName
                    return this.recipient.firstName
                }
            },
            lastName: {
                get() {
                    if(this.recipient._edit) return this.recipient._edit.lastName
                    return this.recipient.lastName
                }
            },
            patronymic: {
                get() {
                    if(this.recipient._edit) return this.recipient._edit.patronymic
                    return this.recipient.patronymic
                }
            },
            companyName: {
                get() {
                    if(this.recipient._edit) return shortenCompanyName(this.recipient._edit.company_name)
                    return shortenCompanyName(this.recipient.company_name)
                }
            },
            id_no: {
                get() {
                    if(this.recipient) return this.recipient.id_no
                }
            },
            company_id_num: {
                get() {
                    if(this.recipient) return this.recipient.company_id_num
                }
            },
            email: {
                get() {
                    if(this.recipient.contacts) return this.recipient.contacts.emails
                    // if(this.recipient.contacts) return this.recipient.contacts.emails.join(', ')
                }
            },
            phone: {
                get() {
                    if(this.recipient.contacts) return this.recipient.contacts.phones
                    // if(this.recipient.contacts) return this.recipient.contacts.phones.join(', ')
                }
            },
            address: {
                get() {
                    if(this.recipient.contacts) return this.recipient.contacts.addresses
                    return []
                }
            }
        },
        methods: {
            addContactModal(type, id) {
                this.$refs.addRecipientContactsModal.setType(type, id)

                this.contactModalType = type

                this.$store.commit('modalShow', { id: 'addRecipientContactsModal' })
            },
            added(data) {
                this.$emit('added', data.recipientId)
            },
            clickAddAddress() {
                this.$refs.addAddressBtn.click()
            }
        }
    }

</script>