<template>
    <div id="content" class="col" v-if="mounting">
        <div class="shadow p-5 m-3 text-center">
            <span class="loading"></span>
        </div>
    </div>
    <div id="content" class="col" v-if="!mounting">
        <AmBreadcrumbs
            :showCurrentCrumb="true"
        />
        <div class="row">
            <div class="col-md-6">
                <h3>{{ $t('claims.import.header') }}</h3>
                <FileUpload class="col-md-8 my-3"
                    ref="excelFile"
                    @added="uploadFile"
                    @removed="removeFile"
                />
            </div>
            <div class="col-md-5">
                <ImportTemplate />
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <h4 class="pt-3" v-show="excelFile">{{ $t('claims.import.number-of-claims') }}: {{ numberOfNotices }}</h4>
                <table class="table w-75" v-show="excelFile">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Адрес</th>
                            <th scope="col" class="text-nowrap">{{ $t('claims.import.amount_owed') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(i, index) in preparedNotices" v-show="index < 5">
                            <td scope="row">{{ index + 1 }}</td>
                            <td v-for="value in i" class="text-nowrap">{{ value }}</td>
                        </tr>
                    </tbody>
                </table>
                <h4 class="mt-3" v-show="errorMsg"><span class="badge bg-danger" v-html="errorMsg"></span></h4>
                <div class="form-check" :class="{ 'd-none': !excelFile||errorMsg }" v-if="false">
                    <input class="form-check-input" type="checkbox" value="" id="checkbox" v-model="createDraftClaim">
                    <label class="form-check-label" for="checkbox">
                        Создать черновик претензии если существует получатель с указанным адресом?
                    </label>
                </div>
                <button class="btn btn-primary mt-4 d-flex align-items-center" :disabled="!excelFile||loading||errorMsg!=''" :class="{ 'd-none': !excelFile||errorMsg, 'loading': loading }" @click="uploadToDB">{{ $t('claim.btn-import') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import FileUpload from '../form/FileUpload.vue'
    import ImportTemplate from './ImportTemplate.vue'

    export default {
        data: () => ({
            excelFile: '',
            filename: '',
            notices: [],
            preparedNotices: [],
            numberOfNotices: 0,
            errorMsg: '',
            loading: false,
            createDraftClaim: false,
            mounting: true
        }),
        components: {
            FileUpload,
            ImportTemplate
        },
        mounted() {
            this.checkOsi()
        },
        methods: {
            async checkOsi() {
                let isOsi = await this.$api.checkOsiStatus()
                if(!isOsi) {
                    this.$router.replace('/dashboard')
                } else{
                    this.mounting = false
                }
            },
            async uploadFile(files) {
                if(!files[0].type.includes('ms-excel') && !files[0].type.includes('spreadsheet')) {
                    return this.$notify({
                        title: this.$t('notify.types.error'),
                        text: 'Неправильный формат',
                        type: 'error'
                    })
                }
                
                let uploadUrl = process.env.VUE_APP_API_URL + '/files/upload'

                let formData = new FormData()
                formData.append("file", files[0], files[0].name)

                let attachment = await axios.post(uploadUrl, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    withCredentials: true
                }).then((response) => {
                    return response.data
                }).catch(function (err) {
                    if(process.env.VUE_APP_DEBUG) console.log(err)
                })

                if(attachment.data) {
                    this.$refs.excelFile.files.push({
                        id: attachment.data,
                        name: attachment.originalname
                    })
                }

                this.excelFile = attachment.data ?? ''
                this.filename = attachment.filename

                if(this.filename) {
                    this.import()
                }
            },
            async import() {
                this.loading = true
                
                let r = await this.$api.importDebtNotices({ filename: this.filename })

                if(r.result === 'SUCCESS') {
                    this.notices = r.data
                    this.numberOfNotices = r.data.length
                    this.prepareNotices()
                    this.loading = false
                } else if(r.result === 'ERROR') {
                    this.notices = r.data
                    this.numberOfNotices = r.data.length
                    
                    for(let e in r.errors) {
                        this.errorMsg += r.errors[e][0].cell + ' - ' + r.errors[e][0].message + '<br>'
                    }

                    this.prepareNotices()
                    this.loading = false
                }
            },
            prepareNotices() {
                for(let i in this.notices) {
                    let preparedNotice = []

                    if(!this.notices[i].length) continue
                    if(process.env.VUE_APP_DEBUG) console.log(this.notices[i])

                    preparedNotice.push(this.notices[i].slice(3,6).join(', '))
                    preparedNotice.push(this.notices[i][6])

                    this.preparedNotices.push(preparedNotice)
                }
            },
            async uploadToDB() {
                this.loading = true
                let body = { filename: this.filename, draftClaim: this.createDraftClaim }
                
                let r = await this.$api.uploadDebtNotices(body)

                if(r.result === 'SUCCESS') {
                    this.$notify({
                        title: this.$t('notify.types.success'),
                        text: r.message,
                        type: 'success'
                    })

                    this.loading = false
                    this.$emit('updateCounters')
                    this.$router.push('/debt-notice/outbox')
                } else {
                    this.loading = false
                    this.$notify({
                        title: this.$t('notify.types.error'),
                        text: r.message,
                        type: 'error'
                    })
                }
            },
            async removeFile(fileId) {
                let url = process.env.VUE_APP_API_URL + `/files/${fileId}/remove`
                
                let removed = await axios.get(url)
                .then((response) => {
                    if(process.env.VUE_APP_DEBUG) console.log(response)

                    let r = response.data

                    return r.result === 'SUCCESS'
                })

                if(removed) {
                    if(process.env.VUE_APP_DEBUG) console.log('excelFile', this.$refs.excelFile.files)

                    this.$notify({
                        title: this.$t('notify.types.success'),
                        text: 'Файл удален',
                        type: 'success'
                    })
                } else {
                    this.$notify({
                        title: this.$t('notify.types.error'),
                        text: 'Не удалось удалить файл',
                        type: 'error'
                    })
                }

                this.excelFile = ''
                this.filename = ''
                this.$refs.excelFile.files = []
                this.$refs.excelFile.clear()
                this.notices = []
                this.preparedNotices = []
                this.numberOfNotices = 0
                this.errorMsg = ''
                // this.data = []
            }
        }
    }

</script>